import { render, staticRenderFns } from "./formss.vue?vue&type=template&id=f6b4532e&scoped=true&"
import script from "./formss.vue?vue&type=script&lang=js&"
export * from "./formss.vue?vue&type=script&lang=js&"
import style0 from "./formss.vue?vue&type=style&index=0&id=f6b4532e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6b4532e",
  null
  
)

export default component.exports